
(function($){

  $('#bookingform').submit(function(e){
    var form = $(this);
    e.preventDefault();
    jQuery.fancybox({ href:'https://booking.cubilis.eu/check_availability.aspx?logisid=3290&startdatum=' + jQuery("#arrival", form).val() + '&einddatum=' + jQuery("#departure", form).val(), type: 'iframe', width: 500, height: 550 });
    return false;
  });

  //Handle the contact form
       var contactform = $('#main-contact-form');
       var target = Routing.generate('site_frontend_ajax_call');
       contactform.submit(function(e) {
           e.preventDefault();
           var form_status = $('<div class="form_status"></div>');
           $.ajax({
               type: contactform.attr('method'),
               url: target,
               beforeSend: function(){
                  contactform.prepend( form_status.html('<p><i class="fa fa-spinner fa-spin"></i> Email is sending...</p>').fadeIn() );
               },
               data: {
                   entity: 'contact',
                   form: contactform.serializeArray()
               },
               success: function(data) {
                   if(data.status == 'ok'){
                       contactform.find("input[type=text], textarea").val("");
                       form_status.html('<p class="text-success">' + data.message + '</p>').delay(5000).fadeOut();
                   }
                   else {
                       form_status.html('<p class="text-error">' + data.message + '</p>').delay(5000).fadeOut();
                   }
               }
           });
       });




	$(".launchgallery").click(function () {
	    $(".fotoviewer:eq(0)").trigger("click");
	    return false;
	});

	$('a.booknow').fancybox({
	    type: 'iframe',
	    width: 482,
	    height: 550
	});

	$('.jquery_hideLabelOnClick').each(function () {
	    var $targetInputField = $(this);
	    var $inputFieldOrigVal = $(this).val();
	    var $inputFieldChanged = false;

	    $targetInputField.focusin(function () {
	        if (!$inputFieldChanged) {
	            $inputFieldOrigVal = $targetInputField.val();
	            $targetInputField.val("");
	        }
	    }).focusout(function () {
	        if ($targetInputField.val() == "" || $targetInputField.val() == $inputFieldOrigVal) {
	            $targetInputField.val($inputFieldOrigVal);
	            $inputFieldChanged = false;
	        } else {
	            $inputFieldChanged = true;
	        }
	    });
	});

	$(".galleryviewer").fancybox({});
  $(".fotoviewer").fancybox({});

	$('a.viewav').fancybox({
	    type: 'iframe',
	    width: 750,
	    height: 300,
	    scrolling: 'no'
	});

	$('#arrival').datepicker({
	    dateFormat: 'dd-mm-yy',
	    minDate: 1,
	    firstDay: 1,
	    onSelect: function (dateStr) {
	        var d = $.datepicker.parseDate('dd-mm-yy', dateStr);
	        d.setDate(d.getDate() + 1);
	        $('#departure').datepicker('setDate', d);
	    }
	});

	$(".slides").responsiveSlides();

	$('#departure').datepicker({
	    minDate: 1,
	    buttonImage: 'images/calendar.png',
	    buttonImageOnly: true,
	    dateFormat: 'dd-mm-yy',
	    firstDay: 1
	});

	$('.jquery_hideLabelOnClick').each(function () {
	    var $targetInputField = $(this);
	    var $inputFieldOrigVal = $(this).val();
	    var $inputFieldChanged = false;

	    $targetInputField.focusin(function () {
	        if (!$inputFieldChanged) {
	            $inputFieldOrigVal = $targetInputField.val();
	            $targetInputField.val("");
	        }
	    }).focusout(function () {
	        if ($targetInputField.val() == "" || $targetInputField.val() == $inputFieldOrigVal) {
	            $targetInputField.val($inputFieldOrigVal);
	            $inputFieldChanged = false;
	        } else {
	            $inputFieldChanged = true;
	        }
	    });
	});


  function showMobileMenu() {
      if ($(window).width() < 768) {
          $("#menu ul li a:not(.active)").css({ "display": "inline-block" }).hide();
          if (!$("#menu ul li a.active").hasClass('clickEnabled')) {
              $("#menu ul li a.active").click(function () {
                  $("#menu ul li a:not(.active)").stop(true, true).fadeToggle("fast");
                  return false;
              }).addClass('clickEnabled');
          }
      } else {
          if ($("#menu ul li a.active").hasClass('clickEnabled')) {
              $("#menu ul li a.active").unbind('click').removeClass('clickEnabled');
          }
          $("#menu ul li a:not(.active)").css({ "display": "inline" }).show();
      }
  }

  function changeLiveStreamText() {
      if ($(window).width() < 768) {
          $("a#livestream span").text("watch live stream");
      } else {
          $("a#livestream span").text("watch");
      }
  }

  $(window).load(function () {
      showMobileMenu();
      changeLiveStreamText();
  });

  $(window).resize(function () {
      showMobileMenu();
      changeLiveStreamText();
  });

})(jQuery);
